import * as React from "react"
import { Link } from "gatsby"

import Layout from '../components/Showcase/layout';
import Seo from '../components/Showcase/seo';
import { StaticImage } from "gatsby-plugin-image"
import {ParallaxProvider, Parallax } from "react-scroll-parallax"


//* Fase 2: Add on-page navigation functionality 
// const handleClick = value => () => {
//   console.log(value)
//   const section = document.querySelector(`${value}`);
//   // console.log(section.id)
//   section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
// }

const SeoAnalyticsReporting = () => (
  <ParallaxProvider>

  <Layout>
    <Seo title="SEO Analytics & Reporting" />
    <section className="intro-section">
      <span className="tag-line">Data Dashboard | SEO Reporting | Optimization Recommendations</span>
      <h1>SEO Analytics & Reporting</h1>
      <p>Much like a car, websites need regular tune-ups. After launch, EAB will keep an eye on SEO and general KPI performance. We share when we see <strong>exciting progress or opportunities to improve performance,</strong> and we provide a custom Google Data Studio dashboard so you can track the data at your leisure.</p>
    </section>
    <section className="banner">
      
      <Parallax translateY={["50", "-30"]}>
     
      <StaticImage
        src="../assets/images/seo-reporting/google-data-studio-dashboard.jpg"
        width={4292}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="Google Data Studio Dashboard"
        />
      </Parallax>

      <figcaption>Google Data Studio Dashboard</figcaption>
    </section>
    <section className="portfolio">
      <p className="intro">Custom Data Dashboard - Monthly SEO Updates - Suggested Content Updates Based on Performance - Keyword Ranking Performance</p>
      <div className="portfolio-card">
        <figure>
        <StaticImage
        src="../assets/images/seo-reporting/monthly-seo-updates.jpg"
        width={1280}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="Monthly SEO Updates"
        />
        
        </figure>
        <figcaption>Monthly SEO Updates</figcaption>
      </div>

      <div className="portfolio-card parallax">
      <Parallax translateY={["50", "-30"]}>
      <figure>
        <StaticImage
        src="../assets/images/seo-reporting/performance-challenges.jpg"
        width={1280}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="SEO Performance Challenges"
        />
        </figure>
        </Parallax>
        <figcaption>SEO Performance Challenges</figcaption>
      </div>

      <div className="portfolio-card">
      <figure>
        <StaticImage
        src="../assets/images/seo-reporting/keyword-ranking-performance.jpg"
        width={1280}
        quality={100}
        placeholder="blurred"
         formats={["auto", "webp", "avif"]}
        alt="Keyword Ranking Performance"
        />
        </figure>
        <figcaption>Keyword Ranking Performance</figcaption>
      </div>
   
    </section>

    <section className="wrapper">
    <div className="section-title side-title">
        <h2>MORE DESIGN STEPS</h2>
        </div>
    <ul className="content-cards-wrapper">
    <li className="content-card">
            <Link to="/discovery-and-planning">
              <StaticImage
                src="../assets/images/discovery-and-planning.png"
                width={700}
                quality={90}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Discovery and Planning"
              />
              <div className="text-container">
                <h3>Discovery & Planning</h3>
                <p>Project Plan | Foundational Strategy | Technology & Platform Plan</p>
              </div>
            </Link>
          </li>
          <li className="content-card">
            <Link to="/information-architecture">
              <StaticImage
                src="../assets/images/information-architecture-navigation-and-global-content-strategy.jpg"
                width={700}
                quality={90}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Discovery and Planning"
              />
              <div className="text-container">
                <h3>Information Architecture, Navigation & Global Content Strategy</h3>
                <p>Content Audit | Information Architecture | Global Strategy</p>
              </div>
            </Link>
          </li>
          </ul>
    </section>
    
  </Layout>
  </ParallaxProvider>
)

export default SeoAnalyticsReporting
